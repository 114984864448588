import request from '@/http.js'
/*****************************会员管理*****************************/
/**
 * @description 会员基本信息 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function memberListApi (param) {
    return request.post(
        '/admin.user/index',
        param
    )
}

/**
 * @description 会员基本信息 -- 详情
 * @param {Object} param data {Object} 传值参数
 */
 export function memberReadApi (param) {
    return request.post(
        '/admin.user/read',
        param
    )
}
/**
 * @description 会员基本信息 -- 详情列表
 * @param {Object} param data {Object} 传值参数
 */
 export function memberReadListApi (param) {
    return request.post(
        '/admin.user/indexList',
        param,
    )
}

export function memberOrderReadListApi (param) {
    return request.post(
        '/admin.user/indexList',
        param,
        false
    )
}

/*****************************推广管理*****************************/
/**
 * @description 会员分红 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
export function memberBonusListApi (param) {
    return request.post(
        '/admin.user/indexShare',
        param
    )
}

/**
 * @description 会员体现 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function memberCashListApi (param) {
    return request.post(
        '/admin.userWithdraw/index',
        param
    )
}

/*****************************办会员卡*****************************/
/**
 * @description 会员 -- 列表
 * @param {Object} param data {Object} 传值参数
 */
 export function createMemberListApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getOpenMemberList',
        param
    )
}

/**
 * @description 会员新增 -- 初始化数据
 * @param {Object} param data {Object} 传值参数
 */
 export function createMemberShowDataApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getUserLevel',
        param
    )
}

/**
 * @description 会员 -- 编辑
 * @param {Object} param data {Object} 传值参数
 */
 export function createMemberEditApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/openMembership',
        param
    )
}

/**
 * @description 会员 -- 删除
 * @param {Object} param data {Object} 传值参数
 */
 export function createMemberDeletetApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/deleteUser',
        param
    )
}

/**
 * @description 会员统计 
 * @param {Object} param data {Object} 传值参数
 */
 export function memberTotalApi (param) {
    return request.post(
        '/admin.statistics/users',
        param
    )
}

/**
 * @description 开通会员判断用户是否存在 
 * @param {Object} param data {Object} 传值参数
 */
 export function isExistMemberApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getInfoByMobile',
        param
    )
}

/**
 * @description 用户详情个人统计
 * @param {Object} param data {Object} 传值参数
 */
 export function personlChartApi (param) {
    return request.post(
        '/admin.statistics/userAnalysis',
        param
    )
}


/**
 * @description 获取门店名称
 * @param {Object} param data {Object} 传值参数
 */
 export function housenameApi (param) {
    return request.post(
        '/admin.warehouse.warehouse/getNameList',
        param
    )
}

/**
 * @description 会员用户分配门店
 * @param {Object} param data {Object} 传值参数
 */
 export function mermberSetHouseApi (param) {
    return request.post(
        '/admin.user/setWarehouse',
        param
    )
}

// /**
//  * @description 会员用户分配门店
//  * @param {Object} param data {Object} 传值参数
//  */
//  export function newAddmermberApi (param) {
//     return request.post(
//         '/admin.user/index',
//         param
//     )
// }

 export function remindListApi (param) {
    return request.post(
        '/admin.user/remindList',
        param
    )
}