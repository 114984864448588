<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-ai-c">
            <el-page-header @back="goBack" content="会员管理 — 基本信息"></el-page-header>
        </div>
        <div class="memberDet">
            <el-row>
                <el-col :span="10">
                    <div style="margin-right:5px">
                        <el-card shadow="never">
                            <el-row type="flex" align="center" :gutter="20" class="top_member">
                                <el-col class="flex flex-ai-c flex-jc-c" :span="4">
                                    <img v-if="!imgStatus" :src="formData.headimgurl" />
                                    <el-avatar v-if="imgStatus" shape="circle" :src="require('../../assets/ava.png')"></el-avatar>
                                </el-col>
                                <el-col :span="8">
                                    <div class="ellipisis">姓名：{{formData.realname?formData.realname: '暂无'}}</div> 
                                    <div class="ellipisis">电话：{{formData.mobile?formData.mobile:'暂无' }} </div> 
                                </el-col>
                                <el-col :span="12">
                                    <div class="ellipisis">注册时间：{{formData.create_time}}</div> 
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="14">
                    <el-card shadow="never">
                        <el-row type="flex" :gutter="20" class="top_member">
                            <el-col :span="6">
                                <label :class="[activeName == 'recharge' ? 'order_col_label' : '']">充值总次数：{{formData.recharge_count}}次 </label><br>
                                <label :class="[activeName == 'recharge' ? 'order_col_label' : '']">充值总金额：{{formData.recharge_sum}}元 </label><br>
                            </el-col>
                            <el-col :span="6">
                                <label :class="[activeName == 'integral' ? 'order_col_label' : '']">会员总积分：{{formData.integral_sum}} </label><br>
                                <label :class="[activeName == 'integral' ? 'order_col_label' : '']">剩余总积分：{{formData.integral}} </label><br>
                            </el-col>
							<el-col :span="6">
							    <label :class="[activeName == 'discount' ? 'order_col_label' : '']">领取优惠券：{{formData.discount_count}}个 </label><br>
							    <label :class="[activeName == 'discount' ? 'order_col_label' : '']">剩余优惠券：{{formData.discount_valid_count}}个 </label><br>
							</el-col>
                            <el-col :span="6">
                                <label :class="[activeName == 'order' ? 'order_col_label' : '']">订单总个数：{{formData.order_count}}个 </label><br>
                                <label :class="[activeName == 'order' ? 'order_col_label' : '']">订单总金额：{{formData.order_amount_sum}}元</label> <br>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
            <!--主要内容-->
            <div class="table memberCard">
                
                <el-tabs v-model="activeName" type="border-card" @tab-click="handleTab">
                    
                    <el-tab-pane label="订单管理" name="order">
                        <div v-if="activeName == 'order'" style="height:calc(100% - 65px);overflow:auto">
                            <div class="selectionBar" style="margin-top:0;">
                                <div class="flex flex-ai-c flex-wrap" style="padding:0 8px;">
                                    <label>场景：</label>
                                    <el-select v-model="select.order_method" placeholder="下单方式" clearable style="width:110px" @change="curr=1;getDetList()">
                                        <el-option v-for="item in selectData['下单方式']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                    <el-select v-model="select.status" placeholder="订单状态" clearable style="width:110px" @change="curr=1;getDetList()">
                                        <el-option v-for="(item,index) in selectData['订单状态']" :label="item" :value="index" :key="index"></el-option>
                                    </el-select>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="table changeorderTab" style="height:calc( 100% - 76px );overflow:auto;">
                                <el-table :data="list" ref="barparent" stripe border height="calc( 100% - 20px )">
                                    <el-table-column align="center" label="下单方式">
                                        <template slot-scope="scope">
                                            {{scope.row.order_method == 1 ? '预存款' : '线上'}}
                                            <div class="item" :style="{width:tableWidth}">
                                                订单编号：{{scope.row.order_sn}}
                                                <!-- <span style="color:#7B84FF;margin-left:40px">
                                                    订单状态：( {{scope.row.status_name}}
                                                    <span style="color:#F56C6C" v-if="scope.row.is_overtime == 1"><span style="color:#909399"> — </span>配送超时</span> )
                                                </span> -->	
                                                <span style="margin-left:40px">创建时间：{{scope.row.create_time}}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="商品信息" class="storeInfo" width="300" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-for="g in scope.row.orderDetails" :key="'goods_'+g.id" class="mesSty">
                                                <el-image class="pic" :src="domain + g.product_pic" fit="cover" :preview-src-list="[domain + g.product_pic]"></el-image>
                                                <div style="width:calc(100% - 105px);">
                                                    <div class="goodsName" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{g.product_name}}</div>
                                                    <p><span class="att">规格：</span>{{g.attr_name?g.attr_name : '暂无'}}</p>
                                                    <p><span class="att">单价：</span>{{g.price}}元</p>
                                                    <p v-if="isMember"><span class="att" >会员价：</span>{{g.vip_price}}元</p>
                                                    <p><span class="att">数量：</span>{{g.number}}</p>
                                                    <p v-if="isMember"><span class="att">小计：</span>{{g.vip_price * g.number}}元</p>
                                                    <p v-if="!isMember"><span class="att">小计：</span>{{g.price * g.number}}元</p>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="postage" align="left" label="配送费" show-overflow-tooltip>
                                    </el-table-column> -->
                                    <el-table-column prop="discount_amount" align="left" width="100" label="优惠券抵扣" show-overflow-tooltip>
                                    </el-table-column>
                                   <!-- <el-table-column prop="integral_amount" align="left" label="积分抵扣" show-overflow-tooltip>
                                    </el-table-column> -->
                                   <!-- <el-table-column prop="balance_amount" align="left" label="余额抵扣" show-overflow-tooltip>
                                    </el-table-column> -->
                                    <el-table-column prop="recharge_amount" align="left" width="100" label="预存款抵扣" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="address" align="left" label="订单类型" show-overflow-tooltip>
                                        <template slot-scope="scope">
											{{scope.row.type==5?'普通订单':scope.row.type==4?'积分兑换':'预售订单'}}
                                        </template>
                                    </el-table-column>
                                    
                                    <el-table-column prop="status_name" align="left" label="订单状态" show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="amount_payable" align="left" label="应付" show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="amount" align="left" label="实付" show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="address" align="left" width="210" label="收货信息" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div class="flex regoods">
                                                <label>姓名：</label>
                                                <div>{{scope.row.name ? scope.row.name : '暂无'}}</div>
                                            </div>
                                            <div class="flex regoods">
                                                <label>电话：</label>
                                                <div>{{scope.row.mobile ? scope.row.mobile : '暂无'}}</div>
                                            </div>
                                            <div class="flex regoods">
                                                <label>地址：</label>
												<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.province+scope.row.city+scope.row.area}}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <!-- 分页 -->
                                <!-- <div class="paging">
                                    <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                                </div> -->
                            </div>
                        </div>
                        <!-- 分页 -->
                        <div class="paging">
                            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                        </div>
                    </el-tab-pane>

                    <!--充值管理-->
                    <el-tab-pane label="充值管理" name="recharge">
                        <div class="el-tab-pane-box">
                            <div class="selectionBar">
                                <div class="flex flex-ai-c flex-wrap">
                                    <label>场景：</label>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="充值时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" height="calc( 100% - 85px )">
                                <el-table-column prop="num" label="充值金额" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="pay_time" label="充值时间" show-overflow-tooltip></el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <div class="paging">
                                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                   <!--  -->
                    <el-tab-pane label="优惠券管理" name="discount">
                        <div class="el-tab-pane-box">
                            <div class="selectionBar">
                                <div class="flex flex-ai-c flex-wrap">
                                    <label>场景：</label>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" height="calc( 100% - 85px )">
                                <el-table-column prop="discount_name" label="优惠券名称" show-overflow-tooltip></el-table-column>
                                <el-table-column label="优惠券类型" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span>{{scope.row.type == 1 ? '新人优惠' : '会员购物'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="full" label="满够金额" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="reduce" label="优惠金额" show-overflow-tooltip></el-table-column>
                                <el-table-column label="使用时间" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span>{{scope.row.use_start_time }} 至 {{scope.row.use_end_time}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="status_name" label="使用状态" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="use_start_time" label="领取时间" show-overflow-tooltip></el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <div class="paging">
                                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
					
					<!--积分管理-->
					<el-tab-pane label="积分管理" name="integral">
					    <div class="el-tab-pane-box">
					        <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" height="calc( 100% - 85px )">
								<el-table-column prop="create_time" label="时间" show-overflow-tooltip></el-table-column>
					            <el-table-column prop="type_name" label="类型" show-overflow-tooltip></el-table-column>
								<el-table-column prop="direct" label="记录" show-overflow-tooltip>
									<template slot-scope="scope">
									    <span>{{scope.row.direct}}{{scope.row.num}}</span>
									</template>
								</el-table-column>
					        </el-table>
					        <!-- 分页 -->
					        <div class="paging">
					            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
					        </div>
					    </div>
					</el-tab-pane>
					<!--品类会员购买记录-->
				   <el-tab-pane label="会员购买记录" name="member"  v-if="formData.member_id>0">
					   <div class="el-tab-pane-box">
						   <div class="selectionBar">
							   <div class="flex flex-ai-c flex-wrap">
								   <label>场景：</label>
								   <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
								   </el-date-picker>
							   </div>
						   </div>
						   <el-row style="margin-bottom: 10px;">
							 <!-- <div>会员订单</div> -->
						     <el-col :span="24">
						           <el-card shadow="never">
						               <el-row type="flex" :gutter="20" class="top_member">
										   <el-col :span="6">
										   	  订单编号：{{member.order_sn}}
										   </el-col>	
						                   <el-col :span="6">
											  会员名称：{{member.name}}
						                   </el-col>
										   <el-col :span="3">
										   	  价格：{{member.price}}									  
										   </el-col>
										   <el-col :span="6">
										      到期时间：{{member.end_time}}
										   </el-col>
										   <el-col :span="6">
										   	 支付时间：{{member.create_time}}
										   </el-col>
						               </el-row>
						           </el-card>
						       </el-col>
						   </el-row>
						   <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getDetList()' height="calc( 100% - 170px )">
							   <el-table-column prop="order_sn" width="300" label="续费订单编号" show-overflow-tooltip></el-table-column>
							   <el-table-column prop="name" label="名称" show-overflow-tooltip></el-table-column>
							   <el-table-column prop="price" label="价格" show-overflow-tooltip></el-table-column>
							   <el-table-column prop="renew_time" label="续费时间" show-overflow-tooltip></el-table-column>
							   <el-table-column prop="end_time" label="到期时间" show-overflow-tooltip></el-table-column>
						   </el-table>
						   <div class="paging">
							   <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
						   </div>
					   </div>
				   </el-tab-pane>
					

                </el-tabs>

            </div>
        </div>
    </div>
</template>
<script>
import { memberReadApi, memberReadListApi, personlChartApi } from '@/api/member.js';
import { orderStatusListApi } from '@/api/order.js';
var echarts = require('echarts');
export default {
    data() {
        return {
            formData: {},
            list: [],
            select: {},
            chartsData: {},

            selectData: {
                '下单方式': [{
                    id: 1,
                    name: '预存款'
                },{
                    id: 2,
                    name: '线上'
                }],
                '配送方式': [{
                    id: 1,
                    name: '门店配送'
                }, {
                    id: 2,
                    name: '到店自取'
                }],
                '订单类型': [{
                    id: 0,
                    name: '普通订单'
                }, {
                    id: 1,
                    name: '秒杀'
                }, {
                    id: 2,
                    name: '拼团'
                }],
                '发票': [{
                    id: 1,
                    name: '是'
                }, {
                    id: 2,
                    name: '否'
                }]
            },

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            activeName: 'order',

            dateRadio: 'month',

            inputyear: '',
            inputmonth: '',
            type: '',
            tableWidth:0,

            imgStatus: false,
			
			member:{},
            isMember:''
            // order_amount_sum:0,
        }
    },
    mounted() {
        this.tableWidth = this.$refs.barparent.bodyWidth;
        this.isMember = this.$route.query.isMember;
        this.init();
    },
    watch: {
        isCollapse() {
            let _this = this;
            setTimeout(() => {
                // _this.initCharts();
            }, 300)
        },
        'dateRadio': function () {
            var myDate = new Date();
            switch (this.dateRadio) {
                case 'year':
                    this.inputyear = String(myDate.getFullYear());
                    break;
                case 'month':
                    this.inputmonth = (new Date(myDate.getFullYear(), myDate.getMonth(), 1))
                    break;
                default:
                    break;
            }
            // this.getChart();
        }
    },
    methods: {
        init: function () {
            //获取会员详情
            this.getDet();
            //获取订单状态
            this.getStatusList();
			this.getDetList();
        },
        
        //会员详情
        getDet: function () {
            memberReadApi({
                id: this.$route.query.id
            }).then(response => {
                this.formData = response;
                this.imgStatus = response.headimgurl ? false : true
            })
        },

        //会员信息列表
        getDetList: function () {
            memberReadListApi({
                curr: this.curr,
                row: this.row,
                list_type: this.activeName,
                user_id: this.$route.query.id,
                ...this.select
            }).then(response => {
				
				if(response.member){
					this.member = response.member
					this.list = response.renew;
				}else{
					this.list = response.list;
				}
				this.curr = response.curr;
				this.pages = response.pages;
				this.count = response.count;
				
               
            })
        },
       
        //获取订单状态
        getStatusList: function () {
            orderStatusListApi().then(response => {
                this.selectData['订单状态'] = response;
            })
        },
        //tab切换
        handleTab: function (e) {
            let _this = this;
            this.select = {};
            this.curr = 1;
            this.row = 10;
            this.list = [];
            
            if(this.activeName == 'order'){
                this.$nextTick(() => {
                    _this.tableWidth = _this.$refs.barparent.bodyWidth; 
                })  
            }
			
			
            this.getDetList();
        },
        //返回
        goBack: function () {
            this.$router.go(-1);
        },
        //时间转换
        dataFormat: function (time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getDetList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getDetList();
        }
    },
}
</script>
<style scoped>
.changeorderTab .el-table--enable-row-transition >>> .el-table__body td {
    padding-top: 52px;
}
.changeorderTab :v-deep .el-table tr {
    position: relative;
    width: 100%;
}
.changeorderTab .item {
    background: #f2f3ff;
    padding: 5% 0;
    position: absolute;
    top: 0;
    left: 0px !important;
    z-index: 4;
    text-align: left;
    padding-left: 10px;
    box-sizing: border-box;
}
.changeorderTab .mesSty .pic {
    width: 80px;
    height: 80px;
    margin: 0 10px;
    object-fit: cover;
    border-radius: 3px;
    display: block;
}
.changeorderTab .mesSty {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebeef5;
}

.changeorderTab .mesSty:last-child {
    border: none;
}
</style>